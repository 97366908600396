<template>
  <div class="EShopInfo content_body" :loading="loading">
    <div class="back_f8 martp_20 marbm_20 dis_flex flex_y_center">
      <div class="label dis_flex flex_y_center">门店信息</div>
    </div>
    <el-form ref="form" label-width="100px">
      <el-form-item label="门店名称">
        <span>{{onlineEntity.EntityName}}</span>
      </el-form-item>
      <el-form-item label="门店logo">
        <el-image class="radius5" style="width: 80px; height: 80px" :src="onlineEntity.EntityLogoURL" fit="cover" :preview-src-list="[onlineEntity.EntityLogoURL]"></el-image>
      </el-form-item>
      <el-form-item label="门店照片">
        <el-image v-for="(item,index) in onlineEntity.PhotoURLList" :key="index" class="radius5" style="width: 160px; height: 90px; margin-right: 15px;" :src="item.PhotoURL" fit="cover" :preview-src-list="PhotoURLList_preview">{{item.Data}}</el-image>
      </el-form-item>
      <el-form-item label="客服电话">
        <span>{{onlineEntity.ServiceTelephoneNumber}}</span>
      </el-form-item>
      <el-form-item label="营业时间">
        <span>{{formatBusinessDate()}} {{onlineEntity.BusinessStartTime}} -- {{onlineEntity.BusinessEndTime}}</span>
      </el-form-item>
      <el-form-item label="门店地址">
        <span>{{onlineEntity.AddressDetail}}</span>
      </el-form-item>
      <el-form-item label="店铺简介：">
        <span>{{onlineEntity.Description}} </span>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="editshopInfo" :loading="editLoading">编辑资料</el-button>
      </el-form-item>
    </el-form>

    <!-- 编辑资料弹出层 -->
    <el-dialog title="编辑门店信息" :visible.sync="dialogVisible" width="1000px" :close-on-click-modal="false">
      <el-scrollbar class="el_scrollbar_height">
        <el-form size="small" ref="form" :model="formData" :rules="rules" label-width="100px">
          <el-form-item label="门店名称" required>
            <el-input v-model="formData.EntityName" disabled placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item width="100px" label="门店logo" prop="Logo_file">
            <div class="color_999">只上传1张，建议尺寸宽：高为1:1</div>
            <el-upload action="#" :limit="1" list-type="picture-card" :file-list="formData.Logo_file" :before-upload="($event)=>beforeAvatarUpload($event,true)">
              <i slot="default" class="el-icon-camera-solid" style="font-size: 40px; color: #999"></i>
              <div slot="file" slot-scope="{file}" style="height:100px;width:100px">
                <el-image class="el-upload-list__item-thumbnail" :id="file.uid" :src="file.url" :preview-src-list="showFileList_1" :z-index="9999" fit="cover"></el-image>
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="DialogPreview(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>

                  <span class="el-upload-list__item-delete" @click="handleRemove(file, 'logo')">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="门店照片" prop="Photo_flie">
            <div class="color_999">最多上传5张，建议尺寸宽：高为16:9</div>
            <el-upload action="#" :limit="5" list-type="picture-card" :file-list="formData.Photo_flie" :before-upload="($event)=>storePhotoBeforeAvatarUpload($event)">

              <i slot="default" class="el-icon-camera-solid" style="font-size: 40px; color: #999"></i>
              <div slot="file" slot-scope="{file}" style="height:100px;width:100px">
                <el-image class="el-upload-list__item-thumbnail" :id="file.uid" :src="file.url" :preview-src-list="PhotoURLList_preview" :z-index="9999" fit="cover"></el-image>
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="DialogPreview(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>

                  <span class="el-upload-list__item-delete" @click="handleRemove(file, 'MD')">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="客服电话" prop="ServiceTelephoneNumber">
            <el-input v-model="formData.ServiceTelephoneNumber" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item label="营业日期">
            <el-button @click="selBusinessHours(index)" v-for="(item, index) in businessHours" :key="index" :type="item.bool ? 'primary' : ''">{{ item.name }}</el-button>
          </el-form-item>
          <el-form-item label="营业时间">
            <div>
              <el-time-picker is-range arrow-control v-model="formData.BusinessTime" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
              </el-time-picker>
            </div>
          </el-form-item>
          <el-form-item label="地址" prop="selectedOptions">
            <el-cascader clearable placeholder="请选择省 / 市 / 区" size="small" :options="options" v-model="formData.selectedOptions">
            </el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="AddressDetail">
            <el-input v-model="formData.AddressDetail" placeholder="请输入具体地址" class="marrt_10"></el-input>
            <el-button type="primary" plain @click="getPlaceSearch">搜索地图</el-button>
          </el-form-item>
          <el-form-item label="地图定位" prop="Latitude">
            <!-- 地图容器 -->
            <div id="editContainer">
              <!-- 展示搜索结果的容器 -->
              <div id="editPanel"></div>
            </div>
          </el-form-item>
          <el-form-item label="门店简介：">
            <el-input style="width: 95%" :rows="4" type="textarea" v-model="formData.Description"></el-input>
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="saveBtn" :loading="updateLoading">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/Marketing/EShopManage/EShopInfo";
import {
  regionData,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";
// import AMapLoader from "@/api/gaodeAMapLoader";
import gdMaps from "@/api/gdMap";
import date from "@/components/js/date.js";
import utils from "@/components/js/utils.js";

var Enumerable = require("linq");
var placeSearch;
export default {
  name: "EShopInfo",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    // 手机号验证
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|6|7|8][0-9]{9}$/;
      if (!value) {
        return callback(new Error("电话号码不能为空"));
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (phoneReg.test(value)) {
            callback();
          } else {
            callback(new Error("电话号码格式不正确"));
          }
        }
      }, 100);
    };
    // 营业时间验证
    var businessHours = (rule, value, callback) => {
      if (!this.formData.businessweek.length || !value) {
        return callback(new Error("请选择营业时间"));
      } else {
        return callback();
      }
    };
    return {
      loading: false,
      editLoading: false,
      updateLoading: false,
      dialogVisible: false,
      formData: {
        EntityName: "",
        ServiceTelephoneNumber: "",
        businessweek: [],
        BusinessTime: [new Date(), new Date()],
        selectedOptions: "",
        ProvinceCode: "", //省、
        CityCode: "", //市、
        AreaCode: "", //区、
        AddressDetail: "", //详细地址、
        Longitude: "", //经度、
        Latitude: "", //纬度
        Logo_file: [],
        Photo_flie: [],
        EntityBusinessDate: [],
      },
      rules: {
        Logo_file: [
          { required: true, message: "请选择门店LOGO", trigger: "change" },
        ],
        Photo_flie: [
          { required: true, message: "请选择门店照片", trigger: "change" },
        ],
        phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
        selectedOptions: [
          { required: true, message: "请选择省市区", trigger: "change" },
        ],
        AddressDetail: [
          { required: true, message: "请输入详细地址", trigger: "change" },
        ],
        Latitude: [
          { required: true, message: "请选择详细地址", trigger: "change" },
        ],
      },
      options: regionData, // 不带全部选项
      businessHours: [
        {
          name: "周一",
          id: 0,
          bool: false,
        },
        {
          name: "周二",
          id: 1,
          bool: false,
        },
        {
          name: "周三",
          id: 2,
          bool: false,
        },
        {
          name: "周四",
          id: 3,
          bool: false,
        },
        {
          name: "周五",
          id: 4,
          bool: false,
        },
        {
          name: "周六",
          id: 5,
          bool: false,
        },
        {
          name: "周日",
          id: 6,
          bool: false,
        },
      ],
      marker: "",
      gaodeMap: "",
      onlineEntity: "", // 门店信息
      PhotoURLList_preview: [],
      showFileList_1: [],
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    /**  上传logo  */
    beforeAvatarUpload(file) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        that.$message.error("上传头像图片大小不能超过 3MB!");
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (evt) {
        let base64 = evt.target.result;
        that.$nextTick(function () {
          that.formData.Logo_file = [{ name: "", url: base64 }];
          that.showFileList_1 = [base64];
        });
      };
      return false;
    },

    /**  上传 门店照片  */
    storePhotoBeforeAvatarUpload(file) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        that.$message.error("上传头像图片大小不能超过 3MB!");
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (evt) {
        let base64 = evt.target.result;
        that.$nextTick(function () {
          that.formData.Photo_flie.push({ name: "", url: base64 });
          that.PhotoURLList_preview.push(base64);
        });
      };
      return false;
    },

    // 删除图片
    handleRemove(file, type) {
      let that = this;
      if (type == "logo") {
        that.formData.Logo_file = [];
        that.showFileList_1 = [];
      } else if (type == "MD") {
        const index = that.formData.Photo_flie.findIndex(
          (item) => item.url == file.url
        );
        that.formData.Photo_flie.splice(index, 1);
        that.PhotoURLList_preview.splice(index, 1);
      }
    },

    editshopInfo() {
      let that = this;
      that.editLoading = true;

      that.dialogVisible = true;
      that.GaodeAMapLoaderConf();
    },
    selBusinessHours(e) {
      const that = this;
      const index = e;
      that.businessHours[index].bool = !that.businessHours[index].bool;
    },
    /**  处理营业时间  */
    formatBusinessDate() {
      let that = this;
      let businessWeekStr = "";
      var arr = Enumerable.from(that.onlineEntity.EntityBusinessDate)
        .select((val) => val.Data)
        .toArray();
      var result = [];
      var tmp;
      while ((tmp = arr.shift())) {
        if (result.length == 0) {
          result.push([tmp]);
          continue;
        }
        var e = result[result.length - 1];
        if (tmp == e[e.length - 1] + 1) {
          e.push(tmp);
        } else {
          result.push([tmp]);
        }
      }
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        if (element.length >= 2) {
          businessWeekStr =
            businessWeekStr +
            that.toWeekName(element[0] - 1) +
            "至" +
            that.toWeekName(element[element.length - 1] - 1) +
            "、";
        }
        if (element.length == 1) {
          businessWeekStr =
            businessWeekStr + that.toWeekName(element[0] - 1) + "、";
        }
      }

      return businessWeekStr.slice(0, businessWeekStr.length - 1);
    },
    /**  将数字转换成周几  */
    toWeekName(num) {
      let weekday = new Array(7);
      weekday[0] = "周一";
      weekday[1] = "周二";
      weekday[2] = "周三";
      weekday[3] = "周四";
      weekday[4] = "周五";
      weekday[5] = "周六";
      weekday[6] = "周日";
      return weekday[num];
    },

    /**  获取网店信息  */
    getOnlineEntityInfo() {
      var that = this;
      that.loading = true;
      API.getOnlineEntityInfo()
        .then((res) => {
          if (res.StateCode == 200) {
            that.onlineEntity = res.Data;

            that.onlineEntity.selectedOptions = [
              that.onlineEntity.ProvinceCode,
              that.onlineEntity.CityCode,
              that.onlineEntity.AreaCode,
            ];

            let startTime = that.onlineEntity.BusinessStartTime
              ? new Date(
                  date.formatDate.format(new Date(), "YYYY-MM-DD") +
                    " " +
                    that.onlineEntity.BusinessStartTime
                )
              : new Date();

            let endTime = that.onlineEntity.BusinessEndTime
              ? new Date(
                  date.formatDate.format(new Date(), "YYYY-MM-DD") +
                    " " +
                    that.onlineEntity.BusinessEndTime
                )
              : new Date();
            that.onlineEntity.BusinessTime = [startTime, endTime];

            that.formData = {
              ID: that.onlineEntity.ID,
              EntityName: that.onlineEntity.EntityName,
              EntityLogoURL: that.onlineEntity.EntityLogoURL,
              ServiceTelephoneNumber: that.onlineEntity.ServiceTelephoneNumber,
              BusinessStartTime: that.onlineEntity.BusinessStartTime,
              BusinessEndTime: that.onlineEntity.BusinessEndTime,
              BusinessTime: that.onlineEntity.BusinessTime,
              Description: that.onlineEntity.Description,
              EntityBusinessDate: that.onlineEntity.EntityBusinessDate,
              PhotoURLList: that.onlineEntity.PhotoURLList,
              ProvinceCode: that.onlineEntity.ProvinceCode,
              CityCode: that.onlineEntity.CityCode,
              AreaCode: that.onlineEntity.AreaCode,
              AddressDetail: that.onlineEntity.AddressDetail,
              Longitude: that.onlineEntity.Longitude,
              Latitude: that.onlineEntity.Latitude,
              selectedOptions: that.onlineEntity.selectedOptions,
              Logo_file: [],
              Photo_flie: [],
            };

            that.businessHours.forEach((val) => {
              that.formData.EntityBusinessDate.forEach((i) => {
                if (val.id == i.Data - 1) {
                  val.bool = true;
                }
              });
            });

            utils.getCanvasBase64(res.Data.EntityLogoURL).then(
              function (base64) {
                // 这里拿到的是转换后的base64地址，可以做其他操作
                that.formData.Logo_file = [{ url: base64 }];
                that.showFileList_1 = [base64];
              },
              function (err) {
                console.log(err);
              }
            );
            that.onlineEntity.Photo_flie = [];
            that.onlineEntity.PhotoURLList.forEach((val) => {
              utils.getCanvasBase64(val.PhotoURL).then(
                function (base64) {
                  // 这里拿到的是转换后的base64地址，可以做其他操作
                  // that.onlineEntity.Photo_flie.push({ url:base64 })
                  that.formData.Photo_flie.push({ url: base64 });
                  that.PhotoURLList_preview.push(base64);
                },
                function (err) {
                  console.log(err);
                }
              );
            });

            that.formatBusinessDate();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /**  更新门店信息  */
    updateOnlineEntity() {
      var that = this;
      that.updateLoading = true;
      var param = {
        ID: that.formData.ID,
        EntityLogoURL: that.formData.Logo_file[0].url,
        ServiceTelephoneNumber: that.formData.ServiceTelephoneNumber,
        BusinessStartTime: that.formData.BusinessTime
          ? date.formatDate.format(that.formData.BusinessTime[0], "hh:mm:ss")
          : "",
        BusinessEndTime: that.formData.BusinessTime
          ? date.formatDate.format(that.formData.BusinessTime[1], "hh:mm:ss")
          : "",
        Description: that.formData.Description,
        EntityBusinessDate: that.formData.EntityBusinessDate,
        PhotoURLList: Enumerable.from(that.formData.Photo_flie)
          .select((val) => ({ PhotoURL: val.url }))
          .toArray(),
        ProvinceCode: that.formData.selectedOptions[0],
        CityCode: that.formData.selectedOptions[1],
        AreaCode: that.formData.selectedOptions[2],
        AddressDetail: that.formData.AddressDetail,
        Longitude: that.formData.Longitude,
        Latitude: that.formData.Latitude,
      };
      API.updateOnlineEntity(param)
        .then((res) => {
          if (res.StateCode == 200) {
            that.dialogVisible = false;
            that.getOnlineEntityInfo();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.updateLoading = false;
        });
    },

    /**  高德地图 配置  */
    GaodeAMapLoaderConf() {
      let that = this;
      let cont = "editContainer";
      /**  设置定图  */
      let gdMap = new gdMaps({
        dom: cont,
        setting: {
          zoom: 11, //设置地图缩放级别PC取值范围为3-18，移动端为3-19
          resizeEnable: true, //是否监控地图容器尺寸变化
        },
      });
      that.gdAMap = gdMap;
      gdMap.createAMapLoader().then((AMap) => {
        gdMap.setGdControl(AMap);
        AMap.on("click", function (val) {
          that.formData.Longitude = val.lnglat.lng;
          that.formData.Latitude = val.lnglat.lat;
          /**  根据 经纬度 获取 地址信息  */
          gdMap
            .getAddress_geocoder(val.lnglat.lng, val.lnglat.lat)
            .then((option) => {
              // if (that.editOrganization) {
              that.formData.AddressDetail = option.address;
              // } else {
              //   that.ruleForm.AddressDetail = option.address;
              // }

              gdMap.addMark({
                position: [val.lnglat.lng, val.lnglat.lat], //标记中心点
                icon: false, //false 使用默认的，需要自定义的传入图标路径
                title:that.formData.AddressDetail,//给标记添加一个文章窗口描述
                clearMark: true, //是否清除之前的点,如果想添加多个点需要设置为false
              });
              that.getChinaAreaCode(
                option.province,
                option.city,
                option.district
              );
            })
            .catch((fail) => {});
        });
        let ProvinceText = CodeToText[that.formData.ProvinceCode]; // 省 直辖市
        let CityText = CodeToText[that.formData.CityCode]; // 市
        let AreaText = CodeToText[that.formData.AreaCode]; // 区
        let address =
          ProvinceText + CityText + AreaText + that.formData.AddressDetail; //
        gdMap.getAddress_location(address).then((location) => {
          gdMap.changeMapCenter([location.lng, location.lat]);
          gdMap.addMark({
            position: [location.lng, location.lat], //标记中心点
            icon: false, //false 使用默认的，需要自定义的传入图标路径
            title:address,//给标记添加一个文章窗口描述
            clearMark: true, //是否清除之前的点,如果想添加多个点需要设置为false
          });
        });
        that.editLoading = false;
      });

      return;
    },
    /**  更新  */
    updateMarker(lng, lat) {
      let that = this;
      that.marker.setPosition([lng, lat]);
      that.getAddress_geocoder(lng, lat);
    },
    /**   返回IP 定位城市信息  */
    showCityInfo() {
      let that = this;
      //实例化城市查询类
      var citysearch = new AMap.CitySearch();
      //自动获取用户IP，返回当前城市
      citysearch.getLocalCity(function (status, result) {
        console.log(status, result);
        if (status === "complete" && result.info === "OK") {
          if (result && result.city && result.bounds) {
            var province = result.province; //省 直辖市
            var cityinfo = result.city; // 市
            var citybounds = result.bounds; // 经纬度信息
            that.getChinaAreaCode(province, cityinfo);
            // document.getElementById('info').innerHTML = '您当前所在城市：'+cityinfo;
            //地图显示当前城市
            // map.setBounds(citybounds);
          }
        } else {
          // document.getElementById('info').innerHTML = result.info;
        }
      });
    },
    /**  根据 文本获取省市区编码  */
    getChinaAreaCode(province, city, arear) {
      let that = this;
      let provinceCode = TextToCode[province].code;
      let cityStr = city ? city : "市辖区"; //处理直辖市
      let cityinfoCode = TextToCode[province][cityStr].code;
      if (arear) {
        let arearCode = TextToCode[province][cityStr][arear].code;
        // if (that.editOrganization) {
        //   that.ruleOrganization.selectedOptions = [
        //     provinceCode,
        //     cityinfoCode,
        //     arearCode,
        //   ];
        // } else {
        that.formData.selectedOptions = [provinceCode, cityinfoCode, arearCode];
        // }
      } else {
        let arearCodes = TextToCode[province][cityStr];
        console.log(arearCodes);
        let arears = Enumerable.from(arearCodes)
          .where((val) => {
            return (
              val.key != "全部" && val.key != "市辖区" && val.key != "code"
            );
          })
          .toArray();
        if (arears.length > 0) {
          let arearCode = arears[0].value.code;
          // that.ruleForm.selectedOptions = [provinceCode,cityinfoCode,arearCode];
          that.formData.selectedOptions = [
              provinceCode,
              cityinfoCode,
              arearCode,
            ];
        }
      }
    },
    // 实例化点标记
    addMarker(lng, lat) {
      let that = this;
      that.marker = new AMap.Marker({
        position: [lat, lng],
      });
      that.gaodeMap.add(that.marker);
    },
    /**  逆向地理编码  */
    getAddress_geocoder(lng, lat) {
      let that = this;
      var geocoder = new AMap.Geocoder({
        city: "010", //城市设为北京，默认：“全国”
        radius: 1000, //范围，默认：500
      });

      geocoder.getAddress([lng, lat], function (status, result) {
        if (status === "complete" && result.regeocode) {
          let province = result.regeocode.addressComponent.province; // 省
          let city = result.regeocode.addressComponent.city; // 市
          let district = result.regeocode.addressComponent.district; // 区

          let township = result.regeocode.addressComponent.township; // 街道
          let street = result.regeocode.addressComponent.street; // 路
          let streetNumber = result.regeocode.addressComponent.streetNumber; // 号

          var address =
            result.regeocode.formattedAddress.split(district)[1] +
            street +
            streetNumber; // 格式化地址
          // if (that.editOrganization) {
          //   that.ruleOrganization.AddressDetail = address;
          // } else {
          that.formData.AddressDetail = address;
          // }
          that.getChinaAreaCode(province, city, district);
        } else {
          log.error("根据经纬度查询地址失败");
        }
      });
    },
    /**  正向地理编码  */
    getAddress_location(CityText) {
      let that = this;
      var geocoder = new AMap.Geocoder({
        // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
        city: "010",
      });

      geocoder.getLocation(CityText, function (status, result) {
        if (status === "complete" && result.info === "OK") {
          // result中对应详细地理坐标信息
        }
      });
    },

        /** 地点搜索服务插件，提供某一特定地区的位置查询服务   */
    getPlaceSearch() {
      let that = this;
      placeSearch = that.gdAMap.getPlaceSearch(
        "editPanel",
        that.formData.AddressDetail
      );

      //监听marker/列表的选中事件
      new AMap.event.addListener(placeSearch, "selectChanged", function (
        results
      ) {
        let province = results.selected.data.pname; // 省
        // 市  如果是 【直辖市】 省和 市是相同的  联动策略 将 直辖市先的是 修改为 市辖区
        let city =
          results.selected.data.cityname == province
            ? "市辖区"
            : results.selected.data.cityname; // 市 // 直辖市会有问题
        let arear = results.selected.data.adname; // 区
        that.getChinaAreaCode(province, city, arear);
        that.formData.AddressDetail = results.selected.data.address;
        that.formData.Longitude = results.selected.data.location.lng;
        that.formData.Latitude = results.selected.data.location.lat;
      });
    },
    // 保存
    saveBtn() {
      const that = this;
      that.formData.EntityBusinessDate = Enumerable.from(that.businessHours)
        .where((val) => {
          return val.bool;
        })
        .select((val) => ({
          Data: val.id + 1,
        }))
        .toArray();

      that.$refs.form.validate((valid) => {
        that.updateOnlineEntity();
      });
    },
    // 查看大图
    DialogPreview(file) {
      document.getElementById(file.uid).click();
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {
    this.getOnlineEntityInfo();
  },
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {},
};
</script>

<style  lang="scss">
.EShopInfo {
  .label::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 15px;
    margin: 10px 10px;
    background-color: #018dff;
  }
  .el_scrollbar_height {
    height: 60vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    #container {
      width: 100%;
      height: 60vh;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 100px;
      height: 100px;
    }
    .el-upload.el-upload--picture-card {
      width: 100px !important;
      height: 100px !important;
      line-height: 115px;
    }
    #editContainer {
      width: 600px;
      height: 320px;

      #editPanel {
        position: absolute;
        background-color: white;
        max-height: 95%;
        overflow-y: auto;
        top: 0px;
        left: 0px;
        width: 200px;
        z-index: 101;
      }
    }
  }
}
</style>
